/**
 * @generated SignedSource<<43d69e9bb79042352fc4eebb32064712>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilteredSurveyInput = {
  PIISurveys?: boolean | null;
  historicSurveys?: boolean | null;
  trackingParams?: ReadonlyArray<TrackingParam | null> | null;
  webcamSurveys?: boolean | null;
};
export type TrackingParam = {
  key: string;
  value: string;
};
export type SurveysQuery$variables = {
  input?: FilteredSurveyInput | null;
  first?: number | null;
};
export type SurveysQuery$data = {
  readonly dashboardMessage: {
    readonly " $fragmentSpreads": FragmentRefs<"Message_message">;
  } | null;
  readonly gamificationStreak: {
    readonly " $fragmentSpreads": FragmentRefs<"Streak_streak">;
  } | null;
  readonly userData: {
    readonly roles: ReadonlyArray<string>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SurveyList_surveys">;
};
export type SurveysQuery = {
  variables: SurveysQuery$variables;
  response: SurveysQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "formatted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SurveysQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SurveyList_surveys"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "dashboardMessage",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Message_message"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GamificationStreak",
        "kind": "LinkedField",
        "name": "gamificationStreak",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Streak_streak"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserData",
        "kind": "LinkedField",
        "name": "userData",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SurveysQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SurveyConnection",
        "kind": "LinkedField",
        "name": "surveys",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SurveyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Survey",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studyId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supportId",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MoneyAmount",
                    "kind": "LinkedField",
                    "name": "reward",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LockedSurveyInfo",
                    "kind": "LinkedField",
                    "name": "lockedInfo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "statusDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MoneyAmount",
                    "kind": "LinkedField",
                    "name": "baseReward",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DurationDetails",
                    "kind": "LinkedField",
                    "name": "durationDetails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "max",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "median",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "min",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "providerDuration",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tags",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fraudType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdminSurveyData",
                    "kind": "LinkedField",
                    "name": "adminData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "surveyGuid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rankScore",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResponseQuality",
        "kind": "LinkedField",
        "name": "responseQuality",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "score",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Message",
        "kind": "LinkedField",
        "name": "dashboardMessage",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GamificationStreak",
        "kind": "LinkedField",
        "name": "gamificationStreak",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiresAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "length",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unlocksAt",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserData",
        "kind": "LinkedField",
        "name": "userData",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9598ab7c11de2b419278e54c2538a563",
    "id": null,
    "metadata": {},
    "name": "SurveysQuery",
    "operationKind": "query",
    "text": "query SurveysQuery(\n  $input: FilteredSurveyInput\n  $first: Int\n) {\n  ...SurveyList_surveys\n  dashboardMessage {\n    ...Message_message\n    id\n  }\n  gamificationStreak {\n    ...Streak_streak\n    id\n  }\n  userData {\n    roles\n    id\n  }\n}\n\nfragment Message_message on Message {\n  id\n  title\n  message\n  type\n}\n\nfragment Streak_streak on GamificationStreak {\n  expiresAt\n  length\n  unlocksAt\n  id\n}\n\nfragment SurveyItem_survey on Survey {\n  id\n  studyId\n  supportId\n  title\n  reward {\n    formatted\n  }\n  lockedInfo {\n    title\n    description\n  }\n  status\n  statusDescription\n  startedAt\n  baseReward {\n    formatted\n  }\n  duration\n  durationDetails {\n    max\n    median\n    min\n    providerDuration\n  }\n  url\n  imageUrl\n  iconUrl\n  tags\n  fraudType\n  adminData {\n    surveyGuid\n    rankScore\n  }\n}\n\nfragment SurveyList_surveys on Query {\n  surveys(input: $input, first: $first) {\n    id\n    edges {\n      node {\n        id\n        status\n        url\n        ...SurveyItem_survey\n      }\n    }\n  }\n  responseQuality {\n    score\n  }\n}\n"
  }
};
})();

(node as any).hash = "80116071232e719c77f174a7c3f99e99";

export default node;
