import Surveys from 'components/pages/Surveys/Surveys';
import { buildPage } from 'utils/pageHelper';
import { GetStaticProps } from 'next/types';

export default buildPage(Surveys, {
  requiresAuthentication: true,
  metaData: {
    title: 'Surveys',
    description: 'Earn money online with Qmee surveys',
  },
});

export const getStaticProps: GetStaticProps = async (context) => {
  return { props: { hideFooterCta: true } };
};
