import React from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { useAlert } from 'react-alert';
import useTranslate from 'hooks/useTranslate';
import classes from './Surveys.module.scss';
import SurveyList from '../../organisms/SurveyList/SurveyList';
import Streak from '../../organisms/Streak/Streak';
import { SurveysQuery } from './__generated__/SurveysQuery.graphql';
import CameraFilterIcon from '../../assets/CameraFilterIcon.svg';
import PIIFilterIcon from '../../assets/PIIFilterIcon.svg';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import useSurveyFilterInputs from '../../../hooks/useSurveyFilterInputs';
import SuspenseWithIndicator from '../../atoms/SuspenseWithIndicator/SuspendWithIndicator';
import Message from '../../molecules/Message/Message';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import Txt from '../../atoms/Txt/Txt';
import clsx from 'clsx';

export interface Props {}

const query = graphql`
  query SurveysQuery($input: FilteredSurveyInput, $first: Int) {
    ...SurveyList_surveys
    dashboardMessage {
      ...Message_message
    }
    gamificationStreak {
      ...Streak_streak
    }
    userData {
      roles
    }
  }
`;

const Surveys: React.FC<Props> = () => {
  const [filterInputs, { setWebcamSurveys, setPIISurveys }] = useSurveyFilterInputs();
  const { qmee_survey_result } = useQueryStringParams();
  const alert = useAlert();
  const translate = useTranslate();

  const data = useLazyLoadQuery<SurveysQuery>(
    query,
    {
      input: {
        ...filterInputs,
        historicSurveys: true,
      },
      first: null,
    },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  const shouldDisplayAlert = React.useRef(true);

  /*
    Within this useEffect we are displaying an interim alert to show in case the new
    user-inbox "survey complete" modal doesn't display to the user straight away.
  */
  React.useEffect(() => {
    if (qmee_survey_result && shouldDisplayAlert.current) {
      shouldDisplayAlert.current = false;

      alert.show(
        translate(
          'Your recent survey is currently processing. Please check your survey history later for a status update.',
        ),
        {
          type: 'info',
          timeout: 5000,
        },
      );
    }
  }, [alert, qmee_survey_result, translate]);

  const handleUpdatePIIPreferences = () => {
    setPIISurveys(!filterInputs.PIISurveys);
  };

  const handleUpdateWebcamPreferences = () => {
    setWebcamSurveys(!filterInputs.webcamSurveys);
  };

  return (
    <section className={classes.root}>
      {data.dashboardMessage && (
        <Message className={classes.message} messageRef={data.dashboardMessage} />
      )}
      <div className={classes.streakFilterGroup}>
        <div className={classes.filterButtonGroup}>
          <Tooltip
            tipClassName={classes.tip}
            tip={
              <>
                <div>
                  <Txt component="h4">
                    PII Surveys - {filterInputs.PIISurveys ? 'Enabled' : 'Disabled'}
                  </Txt>
                  <Txt component="p">
                    These are surveys that may ask for your Personally Identifiable Information
                  </Txt>
                </div>
              </>
            }
          >
            <button
              type="button"
              className={clsx(classes.filterButton, filterInputs.PIISurveys && classes.enabled)}
              onClick={handleUpdatePIIPreferences}
            >
              <PIIFilterIcon />
            </button>
          </Tooltip>
          <Tooltip
            tipClassName={classes.tip}
            tip={
              <>
                <div>
                  <Txt component="h4">
                    Webcam Surveys - {filterInputs.webcamSurveys ? 'Enabled' : 'Disabled'}
                  </Txt>
                  <Txt component="p">
                    These are surveys that may ask to use your webcam as part of the survey
                  </Txt>
                </div>
              </>
            }
          >
            <button
              type="button"
              className={clsx(classes.filterButton, filterInputs.webcamSurveys && classes.enabled)}
              onClick={handleUpdateWebcamPreferences}
            >
              <CameraFilterIcon />
            </button>
          </Tooltip>
        </div>
        {data.gamificationStreak && <Streak streakRef={data.gamificationStreak} />}
      </div>
      {data && (
        <SuspenseWithIndicator>
          <SurveyList surveysRef={data} showFinished></SurveyList>
        </SuspenseWithIndicator>
      )}
    </section>
  );
};
export default Surveys;
